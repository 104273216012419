<template>
  <div class="van-main contract-list">
    <van-search
      v-model="searchForm.contractName"
      class="van-main-search"
      shape="round"
      left-icon=""
      show-action
      placeholder="可输入签署方名称"
      @search="onSearch"
    >
      <template #action>
        <div @click="onSearch">
          搜索
        </div>
      </template>
    </van-search>
    <div class="van-refresh-list">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="finishedText"
          @load="onLoad"
        >
          <div v-for="item in list" :key="item.id" class="contract-item">
            <div>
              <div class="fl">
                <p><span>{{ item.contractName }}</span></p>
                <p><span>{{ item.contractNumber }}</span></p>
                <p><span>{{ item.createTime }}</span></p>
              </div>
              <div class="fl">
                <p class="contract-status">
                  {{ item.state_Name }}
                </p>
                <van-button plain round size="small" type="info" native-type="button" @click="detail(item)">
                  查看详情
                </van-button>
              </div>
              <div class="clearfix" />
            </div>
            <van-divider />
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <back-button />
  </div>
</template>

<script>
import BackButton from '@/components/back-button'
import { Dialog, Cell, CellGroup, Search, List, Divider, Button, PullRefresh } from 'vant'
export default {
  components: {
    BackButton,
    [Dialog.Component.name]: Dialog.Component,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Search.name]: Search,
    [List.name]: List,
    [Button.name]: Button,
    [Divider.name]: Divider,
    [PullRefresh.name]: PullRefresh
  },
  data () {
    return {
      searchForm: {
        contractName: '',
        pageNum: 1,
        pageSize: 10
      },
      finishedText: '没有更多了',
      loading: false,
      refreshing: false,
      finished: false,
      tableList: {
        list: []
      },
      list: []
    }
  },
  created () {
  },
  methods: {
    onSearch () {
      this.searchForm.pageNum = 1
      this.finishedText = '没有更多了'
      this.getList()
    },
    getList () {
      this.searchForm.enterpriseId = this.$store.state.employeeInfo.enterpriseId
      this.api.contract.listPage(this.searchForm).then(result => {
        this.tableList = result.data.value
        if (this.tableList.pages === 0 && this._.isEmpty(this.tableList.list)) {
          this.list = []
          this.finishedText = '暂无数据'
          this.finished = true
        } else {
          if (this.tableList.pageNum === 1) {
            this.list = result.data.value.list
          } else {
            this.list = this.list.concat(result.data.value.list)
          }
          this.loading = false
          if (this.tableList.pageNum < this.tableList.pages) {
            this.searchForm.pageNum += 1
          } else {
            this.finished = true
          }
        }
      }).catch(() => {
        this.finished = true
        this.refreshing = false
        this.finishedText = '暂无数据'
      }).finally(() => {
        this.refreshing = false
      })
    },
    onRefresh () {
      // 清空列表数据
      this.finished = false
      this.loading = true
      this.searchForm.pageNum = 1
      this.getList()
    },
    onLoad () {
      this.getList()
    },
    detail (row) {
      this.$router.push({ name: 'myContractDetail', query: { id: row.sealId } })
    }
  }
}
</script>

<style lang="less" scoped>
.contract-list {
  .van-list {
    margin-top: 0px;
    margin-bottom: 40px;
  }
  .contract-item {
    font-size: 14px;
    margin-top: 10px;
    >div>div:first-child {
      width: 70%;
      p {
        margin-left: 10px;
        >span {
          display: inline-block;
          height: 20px;
          line-height: 20px;
          vertical-align: middle;
        }
      }
    }
    >div>div:nth-child(2) {
      width: 30%;
      text-align: center;
      .contract-status {
        color: #F59A23;
        font-size: 13px;
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
    .van-divider {
      margin-top: 5px;
    }

  }
}

</style>
